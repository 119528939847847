<template>
  <v-row justify="center">
    <v-dialog
      :value="visible"
      persistent
      max-width="600"
    >
      <v-card class="pa-8">
        <v-row class="justify-end">
          <v-btn
            icon
            color="primary"
            @click="$emit('close-dialog-confirm-accept-or-reject-team', mode)"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-icon x-large :color="iconInfo.color" v-text="iconInfo.name" />
        </v-row>
        <v-row>
          <v-card-text>
            <div>{{ message }}</div>
          </v-card-text>
        </v-row>
        <v-card-actions
          class="justify-space-around align-center"
        >
          <v-btn
            color="primary"
            min-width="12rem"
            @click="$emit('confirm-accept-or-reject-team', mode)"
          >
            {{ textButtonAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'ConfirmAcceptOrRejectTeamDialog',
  props: {
    mode: {type: String, required: true},
    visible: {type: Boolean, required: true}
  },
  computed: {
    message() {
      return (this.mode === 'accept') ? this.$t('team.approval.messageConfirmAccepted') : this.$t('team.approval.messageConfirmReject')
    },
    textButtonAction() {
      return (this.mode === 'accept') ? this.$t('team.approval.buttons.acceptTeam') : this.$t('team.approval.buttons.rejectTeam')
    },
    iconInfo() {
      const name = (this.mode === 'accept') ? 'lightbulb_outline' : 'warning_outline'
      const color = (this.mode === 'accept') ? 'primary' : 'yellow'
      return { name, color } 
    }
  },
}
</script>